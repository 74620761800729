<template>
    
    <main ref="resumen" id="resumen">
        <div class="card">
            <div class="card-body">
                <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
                <div class="mt-2">
                    <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
                </div>
                <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
                <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
                <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
                <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
                <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div>
                <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
                <div class="py-2"><aiepiShow :idHistoria="idHistoria"></aiepiShow></div>
                <div class="py-2"><valeShow :idHistoria="idHistoria"></valeShow></div>
                <div class="py-2"><indexEvaluacionShow :idHistoria="idHistoria" :idUsuario="idUsuario"></indexEvaluacionShow></div>
                <button class="btn btn-lg btn-primary" @click="download()">Imprimir</button>
                <img :src="imgData">
            </div>
        </div>
    </main>

</template>
<script>
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import motivoConsultaShow from "../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../base/impresionDX/impresionDxShow";
import aiepiShow from "../base/baseAiepi/index/aiepiShow";
import valeShow from "../base/baseVale/valeShow";
import indexEvaluacionShow from "../base/baseEscalaAbreviadaDesarrollo/index/indexEvaluacionShow";
import exploracionFisicaShow from "../base/examenFisico/exploracionFisicaShow";

import { jsPDF } from "jspdf";
import historiaClinicaService from '../../../services/historiaClinicaService';
import usuarioService from '../../../services/usuarioService';

export default {
    components:{motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    aiepiShow,
    valeShow,
    indexEvaluacionShow,exploracionFisicaShow,modalInformacionUsuario,cardInformacionUsuario},
    methods:{
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async download() {
            /* let pdfRef = this.$refs.resumen;
            const options = {type: 'dataURL'};
            const imgData = await this.$html2canvas(pdfRef, options);
            this.imgData = imgData */

            

            var doc = new jsPDF('p', 'mm', [297, 210]);

            doc.html(document.querySelector('#resumen'), {
                callback: function (doc) {
                    doc.save();
                },
                x: 10,
                y: 10,
                width: 190,
                windowWidth : 1000,
            });
        },
    },
    data () {
        return {
            imgData : '',
            idHistoria:'',
            idUsuario:'',
            usuario: {},
        }
    },
    watch:{
        idUsuario : async function(){
           const response = await historiaClinicaService.show(this.idHistoria);
            this.idUsuario=response.data.id_usuario;
        },
        idHistoria : async function(){
           this.idHistoria=this.$route.params.id;
        }
    },
    async created () {
        this.idHistoria=this.$route.params.id;
        const response = await historiaClinicaService.show(this.idHistoria);
        this.idUsuario=response.data.id_usuario;
        await this.cargarUsuario(this.idUsuario);
    },
}
</script>
<style scoped>
.resumen-container {
    height:300px;
    overflow-y:scroll
}

</style>