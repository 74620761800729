<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Aiepi</span> </div>
            <div class="card-body">
                <div class="table-responsive" v-for="sec in control" :key="sec.id">
                    <div>
                        <span class="text-info">Seccion {{sec.seccion.nombre}}</span>
                        <table class="table table-striped table-bordered">
                            <tr>
                                <th>Item</th>
                                <th>Respuesta</th>
                            </tr>
                            <tr v-for="ite in sec.respuestas" :key="ite.id">
                                <td>
                                    {{ite.item.nombre}}
                                </td>
                                <td>
                                    {{ite.respuesta}}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ul>
                                        <li v-for="dx in sec.diagnosticos" :key="`dx_${dx.id}`">
                                            {{dx.diagnostico.diagnostico}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import seccionAiepiService from '../../../../../services/seccionAiepiService';
import historiaAiepiSeccionService from "../../../../../services/historiaAiepiSeccionService";
import historiaAiepiDxService from '../../../../../services/historiaAiepiDxService';
export default {
    props: ['idHistoria'],
    data(){
        return{
            id:'',
            secciones:{},
            control:[],
            diagnosticos:{},
        }
    },
    methods: {
        async cargarSecciones(){
            const response = await seccionAiepiService.index();
            this.secciones = response.data;
        },
        async cargarDiagnosticos(){
            const response = await historiaAiepiDxService.showByHcAiepi(this.id);
            this.diagnosticos = response.data;
        },
        async open(){
            const response = await historiaAiepiSeccionService.resumenShowByHc(this.idHistoria);
            this.control=response.data;
        },
    },
    created() {
        this.cargarSecciones();
        this.open();
        this.cargarDiagnosticos();
    }
}
</script>

