<template>
    <main>
        <h6 class="text-info">Items Valoracion</h6>
        <table class=" table table-striped table-sm table-bordered font-size-12">
            <tr>
                <th>Rango</th>
                <th>Reporte de padre</th>
                <th>Observacion Directa</th>
                <th>Respuesta</th>
                <th>Observacion Directa</th>

            </tr>
            <tr v-for="item in items" :key="item.id">
                <td>{{item.edad_inicio}} {{item.tipo_edad_inicio}} a {{item.edad_final}} {{item.tipo_edad_final}}</td>
                <td><b>{{item.tipo_item}}:</b> {{item.reporte_padres}}</td>
                <td><b>{{item.tipo_item}}:</b> {{item.observacion_directa}}</td>
                <td class="w-10">{{item.respuesta}}</td>
                <td class="w-10">{{item.item_observacion_directa}}</td>
            </tr>
        </table>
    </main>
</template>
<script>
import itemsValoracionValeService from "../../../../../services/itemsValoracionValeService";
import historiaItemsValoracionValeService from "../../../../../services/historiaItemsValoracionValeService";
import {isEmpty} from "lodash";
export default {
    props: ["idHistoria", "finalizada", "idUsuario", "usuario"],
    data(){
        return {
            respuesta: {},
            item_observacion_directa: {},
            items:{},
            tieneRespuestas: false,
            tieneObservaciones: false
        }
    },
    methods:{
        async cargarItems(){

          const response = await itemsValoracionValeService.index({
              fecha_nacimiento : this.usuario.fecha_nacimiento
            });

            const respuestas = await historiaItemsValoracionValeService.showByHc(this.idHistoria);

            if(!isEmpty(respuestas.data)) {
                this.tieneRespuestas = true;
                this.tieneObservaciones = true;
            }
            
            
            this.items = response.data.map((item) => {
                    
                let respuesta = '';
                let item_observacion_directa='';

                if(!isEmpty(respuestas.data)){
                    
                    const tieneRespuesta = respuestas.data.find(x => parseInt(x.id_items_valoracion) === parseInt(item.id));
                    
                    if(tieneRespuesta !== undefined){
                        respuesta = tieneRespuesta.respuesta;
                    }

                    const tieneObservacion = respuestas.data.find(x => parseInt(x.id_items_valoracion) === parseInt(item.id));
                    
                    if(tieneObservacion !== undefined){
                        item_observacion_directa = tieneObservacion.item_observacion_directa;
                    }
                }

                return {
                    ...item,
                    respuesta: respuesta,
                    item_observacion_directa: item_observacion_directa,
                };
            });
        },
    },
    created(){
        this.cargarItems();
    }
}
</script>
<style scoped>

.font-size-12 {
  font-size: 12px;
}

.w-10{
    width: 10%;
}

</style>