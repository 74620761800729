import Service from "./Service";
const baseurl = '/api/historia-diagnostico-aiepi-usuario';
export default {
    
    resumenShowByHc(id_historia) {
        return Service.get(`${baseurl}/resumen-show-by-hc/${id_historia}`);
    },
    showByHcAiepi(id) {
        return Service.get(`${baseurl}/show-by-hc-aiepi/${id}`);
    },
}