import Service from "./Service";
const baseurl = '/api/historia-aiepi-seccion-usuario';
export default {
    index(filter = {}) {

        return Service.get(`${baseurl}/`, {
            params: {
                ...filter
            }
        });
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showByHc(id_historia,id_seccion) {
        return Service.get(`${baseurl}/show-by-hc/${id_historia}/${id_seccion}`);
    },
    resumenShowByHc(id_historia) {
        return Service.get(`${baseurl}/resumen-show-by-hc/${id_historia}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
}