<template>
  <main>
    <div class="t">
      <div class="card-header bg-light "><span><i class="fa fa-chart-bar"> </i> Escala Abreviada de Desarrollo</span> </div>
      <div class="card-body">
        <div class="row pt-2">
          <div class="col-12" v-for="item in dimensiones" :key="item.id">
            <h6 class="text-info">{{item.nombre}}</h6>
            <div class="tab-content" id="myTabContent">
              <div class="table-responsive">
                <div
                    v-for="(rango, i) in item.rangos"
                    :key="`${rango.id}${i}${item.id}`"
                    class="mt-2"
                >
                  <table v-if="rango_actual.id === rango.id"
                      class="
                        table  table-sm table-bordered
                        font-size-12
                      "
                  >
                    <tbody>
                    <tr
                        v-for="(enunciado, i) in rango.enunciados"
                        :key="`${enunciado.id}`"
                    >
                      <td
                          v-if="i === 0"
                          class="text-center align-middle w-25"
                          :rowspan="rango.enunciados.length"
                      >
                        {{ rango.nombre }}
                      </td>
                      <td class="w-10 align-middle text-center">
                        {{ enunciado.orden }}
                      </td>
                      <td class="w-50 align-middle text-center">
                        {{ enunciado.enunciado }}
                      </td>
                      <td class="w-25 align-middle text-center">
                        <div class="form-check form-check-inline">
                          {{enunciado.respuesta}}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-12  justify-content-end">
                  <label class="col-sm-12 col-form-label text-right text-primary"
                  >Total acumulados al inicio: {{item.total_acumulado_inicio}}</label
                  >
                  <label class="col-sm-12 col-form-label text-right text-primary"
                  >Número de items correctos: {{item.total_items_correctos}}</label
                  >
                  <label class="col-sm-12 col-form-label text-right text-primary"
                  >Total (PD): {{item.total_items_correctos + item.total_acumulado_inicio}}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import dimensionesEscalaAbreviadaDesarrolloService from "../../../../../services/dimensionesEscalaAbreviadaDesarrolloService";
import evaluacionEscalaAbreviadaDesarrolloService from "../../../../../services/evaluacionEscalaAbreviadaDesarrolloService";

import {isEmpty} from 'lodash';
import rangoEscalaAbreviadaDesarrolloService from "../../../../../services/rangoEscalaAbreviadaDesarrolloService";
import usuarioService from "../../../../../services/usuarioService";

export default {
  props: ["idHistoria", "finalizada", "idUsuario"],
  data() {
    return {
      id_usuario: "",
      id_historia: "",
      id_historia_evaluacion:'',
      dimensiones: [],
      usuario: {},
      rango_actual: {},
      has_evaluacion: false,
    };
  },
  methods: {
    async cargarDimensiones() {
      const response =
          await dimensionesEscalaAbreviadaDesarrolloService.index();

      let dimensiones = response.data;

      const respuesta_historia =
          await evaluacionEscalaAbreviadaDesarrolloService.showByHc(
              this.idHistoria
          );

      if(!isEmpty(respuesta_historia.data)){
        this.has_evaluacion=true;
      }

      let rangos = {};

      dimensiones.forEach((x) => {
        x.enunciados.forEach((y) => {
          if (rangos[y.rango.id] === undefined) {
            rangos[y.rango.id] = {
              ...y.rango,
            };
          }
        });
      });

      dimensiones.forEach((x, i) => {
        let enunciados = [];

        let dimension_respuesta = {};

        if (!isEmpty(respuesta_historia.data)) {
          dimension_respuesta = respuesta_historia.data.find(
              (resHistoria) => resHistoria.id_dimension === x.id
          );
        }

        for (const rango in rangos) {
          enunciados.push({
            ...rangos[rango],
            enunciados: x.enunciados
                .filter((e) => e.id_rango === parseInt(rango))
                .map((en) => {

                  let respuesta = "";

                  if (!isEmpty(dimension_respuesta)) {

                    const hasRespuesta = dimension_respuesta.respuesta.find(
                        (res) => parseInt(res.id_enunciado) === parseInt(en.id)
                    );

                    if(hasRespuesta !== undefined) {
                      respuesta = parseInt(hasRespuesta.respuesta)
                    }
                  }

                  return {
                    ...en,
                    respuesta: respuesta,
                  };
                }),
          });
        }

        dimensiones[i]["rangos"] = enunciados;

        dimensiones[i]["total_items_correctos"] = 0;
        dimensiones[i]["total_acumulado_inicio"] = dimensiones[i][
            "rangos"
            ].reduce((total, rango) => {
          if (rango.orden < this.rango_actual.orden) {
            return total + rango.enunciados.length;
          }

          return total;
        }, 0);

        enunciados = [];
      });

      this.dimensiones = dimensiones;
    },
  },
  async created() {

    //if(isEmpty(this.idUsuario)) return ;

    const response = await usuarioService.show(this.idUsuario);
    this.usuario = response.data;

    const res = await rangoEscalaAbreviadaDesarrolloService.showByBirthday(
        this.usuario.fecha_nacimiento
    );
    this.rango_actual = res.data;

    await this.cargarDimensiones();
  },
  watch: {
    dimensiones: {
      handler: function () {
        this.dimensiones.forEach((x, i) => {
          let total_items_correctos = x.rangos.reduce((total, rango) => {
            const total_rango = rango.enunciados.reduce(
                (total_rango, enunciado) => {
                  return (
                      total_rango +
                      (enunciado.respuesta !== "" ? Number(enunciado.respuesta) : 0)
                  );
                },
                0
            );

            return total + total_rango;
          }, 0);

          this.dimensiones[i]["total_items_correctos"] = total_items_correctos;
        });
      },
      deep: true,
    },
    idUsuario : async function(){
      const response = await usuarioService.show(this.idUsuario);
      this.usuario = response.data;

      const res = await rangoEscalaAbreviadaDesarrolloService.showByBirthday(
          this.usuario.fecha_nacimiento
      );
      this.rango_actual = res.data;

      await this.cargarDimensiones();
    }
  },
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>
