<template>
    <main>
        <h6 class="text-info">Condiciones Estructurales</h6>
        <table class=" table table-striped table-sm table-bordered font-size-12">
            <tr>
                <th>Rango</th>
                <th>Condiciones Estructurales</th>
                <th>Presencia</th>
                <th>Integridad</th>
            </tr>
            <tr v-for="item in condiciones" :key="item.id">
                <td>TODAS LAS EDADES</td>
                <td>{{item.condiciones_estructurales}}</td>
                <td>{{item.presencia}}</td>
                <td>{{item.integridad}}</td>
            </tr>
        </table>
    </main>
</template>
<script>
import condicionesEstructuralesValeService from "../../../../../services/condicionesEstructuralesValeService";
import historiaCondicionesEstructuralesValeService from "../../../../../services/historiaCondicionesEstructuralesValeService";
import {isEmpty} from "lodash";
export default {
    props: ["idHistoria", "finalizada", "idUsuario"],
    data(){
        return {
            presencia: {},
            integridad: {},
            condiciones:{},
            tienePresencias:false,
            tieneIntegridades:false,
        }
    },
    methods:{
        async cargarCondiciones(){
            const response = await condicionesEstructuralesValeService.index();
                      
            const respuestas = await historiaCondicionesEstructuralesValeService.showByHc(this.idHistoria);

            if(!isEmpty(respuestas.data)) {
                this.tienePresencias = true;
                this.tieneIntegridades = true;
            }
            
            
            this.condiciones = response.data.map((condicion) => {
                    
                let presencia = '';
                let integridad='';

                if(!isEmpty(respuestas.data)){
                    
                    const tienePresencia = respuestas.data.find(x => parseInt(x.id_condicion) === parseInt(condicion.id));
                    
                    if(tienePresencia !== undefined){
                        presencia = tienePresencia.presencia;
                    }

                    const tieneIntegridad = respuestas.data.find(x => parseInt(x.id_condicion) === parseInt(condicion.id));
                    if(tieneIntegridad !== undefined){
                        integridad = tieneIntegridad.integridad;
                    }
                }

                return {
                    ...condicion,
                    presencia: presencia,
                    integridad: integridad,
                };
            });
        },
    },
    created(){
        this.cargarCondiciones();
    }
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>