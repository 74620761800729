<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-pills"></i> Vale</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <riesgosGeneralesShow :idHistoria="idHistoria" ></riesgosGeneralesShow>
                </div>
                <div class="table-responsive">
                    <condicionesEstructuralesShow :idHistoria="idHistoria" ></condicionesEstructuralesShow>
                </div>
                <div class="table-responsive">
                    <itemsValoracionShow :idHistoria="idHistoria" :usuario="usuario"></itemsValoracionShow>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import riesgosGeneralesShow from "./valeShow/riesgosGeneralesShow";
import condicionesEstructuralesShow from "./valeShow/condicionesEstructuralesShow";
import itemsValoracionShow from "./valeShow/itemsValoracionShow";

export default {
  props: ["idHistoria", "finalizada", "idUsuario", "usuario"],
  components : {riesgosGeneralesShow,condicionesEstructuralesShow,itemsValoracionShow},
}
</script>